<template>
  <template v-for="row in data" :key="`${tableId}_row_${row.id ?? uuidv4()}`">
    <tr
      class="h-15"
      data-cy="table-row"
      :class="[
        {
          'border-b': hasBorder
        },
        {
          'bg-primary bg-opacity-5 selected-row':
            selectedRows.indexOf(row.id) > -1,
          'child-row': isChild,
          'cursor-pointer':
            (rowIsClickable &&
              rowClickCondition(row) &&
              (!rowIsInactive(row) || isSuperAdmin)) ||
            row.children?.length,
          'text-gray-300': rowIsInactive(row),
          'hover:bg-grey-hover':
            selectedRows.indexOf(row.id) === -1 && selectAll
        },
        {
          hidden:
            accordionTable &&
            row['fatherName'] === getRow(row['id']) &&
            !row['open']
        }
      ]"
      @click="
        () => (rowClickCondition(row) ? $emit('row-clicked', row.id) : null)
      "
    >
      <td
        v-if="(selectAll || selectOne) && !row.mockRow"
        class="px-5-25 font-normal"
      >
        <div class="flex items-center">
          <lf-checkbox
            v-if="selectAll"
            v-model="checkedRows"
            name="selectAll"
            :value="row.id"
            :disabled="disabledCheckboxes"
            @change="rowChanged"
          />
          <input
            v-if="selectOne"
            type="radio"
            name="selectOne"
            :value="`${row.id}_${row[selectOneKey]}`"
            :checked="`${row.id}_${row[selectOneKey]}` === checkedRows?.[0]"
            :disabled="disabledCheckboxes"
            @click="radioSelected"
          />
          <table-select-details
            v-if="typeof selectDetailsProvider === 'function'"
            :options="selectDetailsProvider(row)"
          />
          <component
            :is="selectDetailsProvider"
            v-else-if="typeof selectDetailsProvider === 'object'"
            :data="row"
          />
        </div>
      </td>
      <template v-for="column in columns" :key="column.key">
        <td
          class="px-5-25 font-normal"
          :class="[{ 'whitespace-nowrap': column.nowrap }, column.columnClass]"
          :data-cy="`table-row-column-${column.key}`"
        >
          <div v-if="!row.mockRow">
            <!-- Replicates access-control, but gives better performance & less memory usage -->
            <template v-if="hasAccess(new Set(column.acl || []))">
              <!-- added :value prop because v-model only works directly on values (we can't do get(...)) -->
              <!-- we can't v-model an dot nested string accessor e.g. "something.somethingElse" -->
              <lazy-load v-if="column.component" :options="lazyLoadOptions">
                <component
                  :is="column.component"
                  v-bind="{
                    ...(typeof column.props === 'function'
                      ? column.props(row)
                      : column.props || {}),
                    model: row,
                    value: column.formatter
                      ? getFormattedValue(column, row)
                      : get(row, column.key) || ''
                  }"
                  v-if="column.component && showComponent(column, row)"
                  v-model="row[column.key]"
                  v-on="eventHandlers(column, row)"
                >
                  {{
                    column.formatter
                      ? getFormattedValue(column, row)
                      : get(row, column.key)
                  }}
                </component>
              </lazy-load>
              <div v-else-if="column.options" :class="column.class || ''">
                {{
                  getValueFromOptions(
                    column.options,
                    row,
                    column.key,
                    column.placeholder
                  )
                }}
              </div>
              <a
                v-else-if="row.externalLink && column.key === 'linkText'"
                target="_blank"
                class="text-blue-600 underline"
                :href="row.externalLink"
              >
                {{ row[column.key] }}
              </a>
              <div
                v-else
                :class="column.class || ''"
                :style="
                  column.style
                    ? styleColumn(column.style, getFormattedValue(column, row))
                    : undefined
                "
              >
                <router-link
                  v-if="column.redirectTo"
                  :to="getRoute(row.id, column.redirectTo)"
                  class="font-medium"
                >
                  {{ getFormattedValue(column, row) }}
                </router-link>
                <span v-else>
                  <span v-if="accordionTable">
                    <div
                      v-if="row['hasChild'] && column.key === 'category'"
                      class="flex items-center justify-between w-40"
                      @click="onClickRow"
                    >
                      <p class="text-sm font-bold text-headline">
                        {{ getFormattedValue(column, row) }}
                      </p>
                      <icon-base
                        height="10"
                        width="10"
                        :icon="`arrow-${
                          isChildRowActive && row['open'] ? 'down' : 'up'
                        }`"
                        opacity="0.5"
                        class="text-gray-500 transform"
                      />
                    </div>
                    <div
                      v-else
                      class="text-sm"
                      :class="
                        !row['hasChild'] &&
                        column.key === 'category' &&
                        !row['fatherName']
                          ? 'font-bold text-headline'
                          : 'pl-5'
                      "
                    >
                      {{ getFormattedValue(column, row) }}
                    </div>
                  </span>
                  <div
                    v-else
                    :style="
                      column.style
                        ? styleColumn(
                            column.style,
                            getFormattedValue(column, row)
                          )
                        : undefined
                    "
                  >
                    {{ getFormattedValue(column, row) }}
                  </div>
                </span>
              </div>
            </template>
          </div>
          <div v-else>
            <span class="filter blur-sm">Mock Text</span>
          </div>
        </td>
        <template v-if="column.subColumns?.length">
          <td
            v-for="subColumn in column.subColumns"
            :key="`column-${column.key}-subColumn-${subColumn.key}`"
            class="px-5-25 font-normal bg-light-grey"
            :class="{
              'whitespace-nowrap': column.nowrap
            }"
          >
            <template v-if="!row.mockRow">
              <div>
                <template v-if="hasAccess(new Set(column.acl || []))">
                  <!-- added :value prop because v-model only works directly on values (we can't do get(...)) -->
                  <!-- we can't v-model an dot nested string accessor e.g. "something.somethingElse" -->
                  <lazy-load
                    v-if="subColumn.component"
                    :options="lazyLoadOptions"
                  >
                    <component
                      :is="subColumn.component"
                      v-bind="{
                        ...(typeof subColumn.props === 'function'
                          ? subColumn.props(row)
                          : subColumn.props || {}),
                        model: row,
                        value: get(row, subColumn.key) || ''
                      }"
                      v-if="
                        subColumn.component && showComponent(subColumn, row)
                      "
                      v-model="row[subColumn.key]"
                    >
                      {{ get(row, subColumn.key) }}
                    </component>
                  </lazy-load>
                  <div v-else-if="subColumn.options">
                    {{
                      getValueFromOptions(
                        subColumn.options,
                        row,
                        subColumn.key,
                        subColumn.placeholder
                      )
                    }}
                  </div>
                  <a
                    v-else-if="row.externalLink && subColumn.key === 'linkText'"
                    target="_blank"
                    class="text-blue-600 underline"
                    :href="row.externalLink"
                  >
                    {{ row[subColumn.key] }}
                  </a>
                  <div v-else>
                    <router-link
                      v-if="subColumn.redirectTo"
                      :to="getRoute(row.id, subColumn.redirectTo)"
                      class="font-medium"
                    >
                      {{ getFormattedValue(subColumn, row) }}
                    </router-link>
                    <span v-else>
                      {{ getFormattedValue(subColumn, row) }}
                    </span>
                  </div>
                </template>
              </div>
            </template>
            <div v-else>
              <span class="filter blur-sm">Mock Text</span>
            </div>
          </td>
        </template>
      </template>
    </tr>
    <template v-if="row.children?.length > 0 && row.open">
      <table-body
        :data="row.children"
        :columns="columns"
        is-child
        :lazy-load="lazyLoad"
        :row-click-condition="() => null"
      />
    </template>
  </template>
  <tr v-if="noData">
    <td :colspan="columns.length" class="text-center py-4">
      {{ noDataText }}
    </td>
  </tr>
</template>
<script setup lang="ts">
import { type PropType, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useAuth } from "@/hooks/auth";
import { useAccess } from "@/hooks/access";
import isEmpty from "lodash/isEmpty";

import get from "lodash/get";
import LfCheckbox from "../inputs/LfCheckbox.vue";
import LazyLoad from "@/components/LazyLoad.vue";
import TableSelectDetails from "@/components/ui/table/TableSelectDetails.vue";
import type {
  LazyLoadOptions,
  ColumnStyles,
  SelectDetailsProvider
} from "@/models/common";
import type { IDealsColumn } from "@/models/applications";
import { v4 as uuidv4 } from "uuid";

const emit = defineEmits(["rowChanged", "row-clicked", "radioSelected"]);

const props = defineProps({
  columns: {
    type: Array as PropType<Array<IDealsColumn>>,
    default: null
  },
  data: {
    type: [Array, Object],
    default: null
  },
  selectAll: {
    type: Boolean,
    default: false
  },
  selectOne: {
    type: Boolean,
    default: false
  },
  selectOneKey: {
    type: String,
    default: ""
  },
  selectedRows: {
    type: Array,
    default: () => []
  },
  rowIsClickable: {
    type: Boolean,
    default: false
  },
  rowIsInactive: {
    type: Function,
    default: () => false
  },
  rowClickCondition: {
    type: Function,
    required: true
  },
  selectDetailsProvider: {
    type: [Object, Function] as PropType<SelectDetailsProvider>,
    default: null
  },
  noDataText: {
    type: String,
    default: ""
  },
  accordionTable: {
    type: Boolean,
    default: false
  },
  isChild: {
    type: Boolean,
    default: false
  },
  hasBorder: {
    type: Boolean,
    default: true
  },
  lazyLoad: {
    type: [Boolean, Object] as PropType<boolean | LazyLoadOptions>,
    default: true
  },
  disabledCheckboxes: {
    type: Boolean,
    default: false
  },
  showSelectAllOption: {
    type: Boolean,
    default: false
  }
});

const store = useStore();
const { hasAccess } = useAccess();
const checkedRows = ref(props.selectedRows);
const { isSuperAdmin } = useAuth();
const allOptions = computed(() => store.getters["options/all"]);
const noData = computed(() => isEmpty(props.data));
const tableId = uuidv4();
const lazyLoadOptions = computed(() =>
  typeof props.lazyLoad === "object"
    ? props.lazyLoad
    : { disabled: !props.lazyLoad }
);
const rowChanged = (event: Event) => {
  emit(
    "rowChanged",
    (event.target as HTMLInputElement).checked,
    (event.target as HTMLInputElement).value
  );
};
const radioSelected = (event: Event) => {
  emit("radioSelected", (event.target as HTMLInputElement).value);
};

const eventHandlers = (column: IDealsColumn, row: unknown) => {
  if (column.eventHandlers) {
    return Object.entries(column.eventHandlers).reduce(
      (result, [handlerName, handlerFunction]) => {
        if (!result) {
          return {};
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        result[handlerName] = (...args: any[]) => {
          // Call the handler function with the original arguments and add row at the end
          return handlerFunction(...args, row);
        };
        return result;
      },
      {} as IDealsColumn["eventHandlers"]
    );
  }
  return {};
};

const isChildRowActive = ref(false);

const getValueFromOptions = (
  optionType: string,
  row: unknown,
  key: string,
  placeholder: string | undefined
) => {
  const valueId = get(row, key);
  if (!valueId) return placeholder || "-";
  return get(allOptions.value, optionType)[valueId];
};

const styleColumn = (styles: ColumnStyles, value?: string) => {
  if (!styles?.color || !value) return "";
  if (typeof styles.color === "function") {
    return `color: ${styles.color(value)}`;
  }
  return `color: ${
    styles.color[value.toLowerCase() as keyof typeof styles.color] || ""
  }`;
};

const getRow = (id: string) => {
  return id.split("-")[0] || id;
};

const getFormattedValue = (column: IDealsColumn, row: unknown) => {
  if (column.formatModel && column.formatter) {
    return column.formatter(row);
  }
  const value: string = get(row, column.key);
  if (value === undefined || value === null || value === "") {
    return column.placeholder || "-";
  }
  if (column.formatter) {
    return column.formatter(value) || column.placeholder;
  }
  return value;
};

const getRoute = (id: string, redirectTo: IDealsColumn["redirectTo"]) => {
  const base = `${redirectTo?.base}/`;
  const itemId = redirectTo?.addId ? `${id + "/"}` : "";
  const suffix = redirectTo?.suffix ? `${redirectTo.suffix}/` : "";

  return {
    path: base + itemId + suffix,
    query: redirectTo?.query || {}
  };
};
const onClickRow = () => {
  isChildRowActive.value = !isChildRowActive.value;
};

const showComponent = (column: IDealsColumn, row: unknown) => {
  if (!column.removeComponentIfEmpty) {
    return true;
  }
  const value = get(row, column.key);
  if (
    value === undefined ||
    value === null ||
    value === "" ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return false;
  }
  return true;
};

watch(
  () => props.selectedRows,
  (val) => {
    checkedRows.value = val;
  }
);
</script>
